import '../assets/sass/app.scss';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import Home from "./Home";
import TermsAndConditions from "./animated-visual-stimulation/TermsAndConditions";
import PrivacyPolicy from "./animated-visual-stimulation/PrivacyPolicy";
import LocalMpPrivacyPolicy from "./localmpnearme/PrivacyPolicy";
import LocalMpTermsAndConditions from "./localmpnearme/TermsAndConditions";

function App() {
  return (
      <Router>
          <div className='container'>
              <Switch>
                  <Route path="/animated-visual-stimulation/privacy-policy">
                      <PrivacyPolicy />
                  </Route>
                  <Route path="/animated-visual-stimulation/terms-and-conditions">
                      <TermsAndConditions />
                  </Route>
                  <Route path="/localmpnearme/privacy-policy">
                      <LocalMpPrivacyPolicy />
                  </Route>
                  <Route path="/localmpnearme/terms-and-conditions">
                      <LocalMpTermsAndConditions />
                  </Route>
                  <Route path="/">
                      <Home />
                  </Route>
              </Switch>
          </div>
      </Router>
  );
}

export default App;
