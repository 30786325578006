import logoSpiral from '../assets/images/logo-spiral.svg';

function Home() {
    return (
        <div className="app">
            <div className="logo-container">
                <div className='logo-container__box'>
                    <div className="logo">
                        <img className="logo__spiral" src={logoSpiral} alt="logo spiral" />
                    </div>
                    <h1 className="logo__title">Harness <span className="logo__title--dark">Studio</span></h1>
                </div>
                <div className="description">
                    <h2 className="description__title">Building React Web Apps and React Native Apps</h2>
                    <a className="description__email" href="mailto: info@harness-studio.com">info@harness-studio.com</a>
                </div>
            </div>
        </div>
    );
}

export default Home;
